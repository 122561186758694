import React, {useEffect, useState, useRef} from 'react';

import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import IconButton from '@mui/material/IconButton';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';

// icon list
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import axios from "axios";

// color set
import { blue } from '@mui/material/colors';

// heartit components
import TypeInput from '../../components/inputs/TypeInput';

import SoldoutCheckBox from '../../components/inputs/SoldoutCheckBox';
import ViewNumberSelector from '../../components/inputs/ViewNumberSelector';
import ViewTypeSelector from '../../components/inputs/ViewTypeSelector';

import CardBasketView from '../../components/products/CardBasketView';
import CardLoadingView from '../../components/products/CardLoadingView';
import CardView from '../../components/products/CardView';
import ListView from '../../components/products/ListView';

// dialog
import ProductUploadDialog from '../../components/dialogs/ProductUploadDialog';
import ProductImageDialog from '../../components/dialogs/ProductImageDialog';
import ProductStorageDialog from '../../components/dialogs/ProductStorageDialog';

import DeleteConfirmDialog from '../../components/dialogs/DeleteConfirmDialog';

// heartit controller
import ProductController from '../../apis/products/ProductController';

// alert
import { SnackbarProvider, useSnackbar } from 'notistack';

import debounce from 'lodash/debounce';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../../assets/Constants';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// delivery = 0 : 국내배송 ; 1 : 해외배송
// storage.status = 0 : 품절, 1 : 재고 있음, 2 : 부분 재고

function MenuAdminPage(props){

  const navigate = useNavigate();

  const {
    __adminProductOption,
    __adminProductChange,
    __adminProductKeywordChange,
    __adminProductCategoryChange
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [componentHeight, setComponentHeight] = useState({
    actionBar : 0,
    filterTop : 0
  });
  const refList = {
    actionBar : useRef(null),
    filterTop : useRef(null),
    product_list: useRef(null),
    searchKeyword : useRef(null)
  };
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isStorageOpen, setIsStorageOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState({
    open : false,
    key : ""
  });

  const [isProductLoading, setIsProductLoading] = useState(true);

  const [panelExpanded,  setPanelExpanded] = useState(0);
  const [viewType, setViewType] = useState(__adminProductOption.viewType);
  const [onlyOwned, setOnlyOwned] = useState(false);
  const [onlyHearted, setOnlyHeartedOwned] = useState(false);
  const [onlyUploaded, setOnlyUploadedOwned] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [soldout, setSoldout] = useState([true, true]);

  const [typeList, setTypeList] = useState(__adminProductOption.category);

  const [page, setPage] = useState(1);

  const [sortOption, setSortOption] = useState({
    date : 0,
    price : 0,
    name : 0
  });

  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState([]);

  const [imageItem, setImageItem] = useState({
    name : "",
    images : []
  });

  const [storageItem, setStorageItem] = useState({
    name : "",
    options : {}
  });

  const [viewNumber, setViewNumber] = useState(25);


  const handleOnlyOwnedChange = (event) => {
    setOnlyOwned( event.target.checked );
  };

  const handleOnlyHeartedChange = (event) => {
    setOnlyHeartedOwned( event.target.checked );
  }

  const handleOnlyUploadedChange = (event) => {
    setOnlyUploadedOwned( event.target.checked );
  }

  const handleSortChange = (sort) => (event) => {
    const newSort = ((sortOption[sort]+1)%3);
    setSortOption({...sortOption, [sort] : (newSort===2)?-1:newSort });
    setIsProductLoading(true);
  }

  const handleViewTypeChange = (_viewType) => {
    __adminProductChange({viewType: _viewType});
    setViewType(_viewType);

  }

  const handleViewNumberChange = (value) => {
    setIsProductLoading(true);
    setViewNumber(value);
  };

  const handleProductSelect = (id) => (_select) => {
    if(_select){
      const getElement = products.find(element => (element.hpid === id) );
      if(getElement !== null){
        setSelected([...selected, { ...getElement, category:[] }]);
        setPanelExpanded(2);
      }
    }else{
      setSelected(selected.filter((item) => (item.hpid !== id )));
    }

  }

  const handleTypeChange = (event, newValue) => {

    setTypeList((prev) => newValue);
    __adminProductCategoryChange({category:newValue})
  };

  const handleSoldoutChange = (options) => {

  };

  const handleItemAdd = () => {
    navigate("/admin/product/create");
  };

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
        setPage(1);
        setTotalNumber(0);
        setIsProductLoading(true);
      }
   }

  const handlePanelExpand = (event) => {
    if(panelExpanded == 2){
      setPanelExpanded(1);
    }else{
      setPanelExpanded(2);
    }
  }

  const handleBasketInitial = (event) => {
    setSelected([]);
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleBasketUpload = (event) => {
    if(selected.length == 0){
      handleSnackBar("상품을 먼저 한 개 이상 추가해주세요",'error');
    }else{
      setIsUploadOpen(true);
    }
  }

  const handleUploadDialogClose = (event) => {
    setIsUploadOpen(false);
    setSelected([]);
  }

  const handleImageDialogClose = (event) => {
    setIsImageOpen(false);
  }

  const handleStorageDialogClose = (event) => {
    setIsStorageOpen(false);
  }

  const handlePageChange = (event, value) => {
    setIsProductLoading(true);
    setPage(value);
  };

  const handleImageOpen = (id) => (event) => {
    const _product = products.find(element => (element.hpid == id) );
    setImageItem({
      name : _product.name,
      images : _product.images
    });
    setIsImageOpen(true);
  }

  const handleStorageOpen = (id) => (event) => {
    const _product = products.find(element => (element.hpid == id) );
    setStorageItem({
      name : _product.name,
      options : _product.options
    });
    setIsStorageOpen(true);
  }

  const handleUploadProduct = () => {
    // axios를 활용한 업로드 진행
  }

  const getProductSuccess = (response) => {
    refList.product_list.current.scrollTo(0, 0);
    if(response.status === 200){
      setProducts(response.data.products);
      setTotalNumber(response.data.total);
    }
    setIsProductLoading(false);
  };

  const getProductError = (error) => {
    setIsProductLoading(false);
  };

  const deleteProductSuccess = (response) => {
    handleSnackBar("해당 상품이 삭제되었습니다",'success');
    setProducts((prev) => prev.filter((prevItem) => prevItem.key !== deleteOption.key));
  };

  const deleteProductError = (error) => {
    handleSnackBar("해당 상품을 삭제하지 못했습니다",'error');
  };

  const handleDeleteClose = () => {
    setDeleteOption((prev) => ({...prev, open : false}));
  };

  const handleDeleteSelect = () => {
    setDeleteOption((prev) => ({...prev, open : false}));
    ProductController.deleteProduct({key: deleteOption.key}, deleteProductSuccess, deleteProductError);
  };

  const handleDeleteOpen = (key) => () => {
    setDeleteOption((prev) => ({...prev, open : true, key : key}));
  };

  useEffect(() => {
    setComponentHeight({...componentHeight,
      filterTop :  refList.filterTop.current.clientHeight
    });
  }, []);

  useEffect(() => {

  },[page, viewNumber]);

  useEffect(() => {

    if(isProductLoading){
      const keyword = refList.searchKeyword.current.value;
      __adminProductKeywordChange({keyword:keyword});
      ProductController.getProductsAdmin({
        page : page,
        count : viewNumber,
        sort: sortOption,
        query : keyword,
        category : typeList,
        soldout : soldout,
        option : {
          owned : onlyOwned,
          heart : onlyHearted,
          uploaded : onlyUploaded
        }
      }, getProductSuccess, getProductError);
    }
  }, [isProductLoading, typeList]);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative' }}>
      <Grid container spacing={3} sx={{height:"100%", position:'relative'}}>
        <Grid key="product-list-top-search-bar" item sx={{width:{xs : "100%", sm : "280px"} }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{width:"100%", padding:'0px 16px 24px', boxSizing:'border-box'}}
            spacing={1}>
            <Button
              variant="outlined"
              onClick={handleItemAdd}
              sx={{mt:'16px !important', width:'100%'}}>
              상품 추가하기
            </Button>
            <Typography variant="p" component="p" sx={{mt:'16px !important', mb:'16px !important'}}>
              종류 선택
            </Typography>
            <TypeInput value={typeList} onChange={handleTypeChange} sx={{width:"100%"}} />
            <Typography variant="p" component="p" sx={{mt:'24px !important', mb:'16px !important'}}>
              품절 여부
            </Typography>
            <SoldoutCheckBox value={soldout} onChange={handleSoldoutChange} />
            <Typography variant="p" component="p" sx={{mt:'24px !important', mb:'16px !important'}}>
              뷰 타입
            </Typography>
            <ViewTypeSelector
              value={viewType}
              onChange={handleViewTypeChange}/>
          </Stack>
        </Grid>
        <Grid key="product-list-body" item container xs={12} sm md lg={8}
          sx={{height:'100%', position:'relative'}}>
          <Grid
            container
            ref={refList.filterTop}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{mb:"8px", padding:'16px'}}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}>
            <Grid
             item>
              <TextField
                id="outlined-basic"
                label="검색어"
                size="small"
                variant="outlined"
                onKeyDown={handleKeyPress}
                defaultValue={__adminProductOption.keyword}
                inputRef={refList.searchKeyword}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  ),
                }} />
              </Grid>
              <Grid
                item>
                <ViewNumberSelector
                  value={viewNumber}
                  onChange={handleViewNumberChange}/>
              </Grid>
              <Grid
                item>
                <Button disableElevation variant={sortOption.date === 0 ? "outlined" : "contained"} size="small"
                  onClick={handleSortChange("date")}
                  endIcon={
                    sortOption.date === 0 ? <HorizontalRuleIcon /> :
                    (
                      sortOption.date === 1 ? <ArrowDropUpTwoToneIcon /> :
                      <ArrowDropDownTwoToneIcon />
                    )
                  }>
                  업데이트 일자
                </Button>
              </Grid>
              <Grid
                item>
                <Button disableElevation variant={sortOption.name === 0 ? "outlined" : "contained"} size="small"
                  onClick={handleSortChange("name")}
                  endIcon={
                    sortOption.name === 0 ? <HorizontalRuleIcon /> :
                    (
                      sortOption.name === 1 ? <ArrowDropUpTwoToneIcon /> :
                      <ArrowDropDownTwoToneIcon />
                    )
                  }>
                  이름
                </Button>
              </Grid>
              <Grid
                item>
                <Button disableElevation variant={sortOption.price === 0 ? "outlined" : "contained"} size="small"
                  onClick={handleSortChange("price")}
                  endIcon={
                    sortOption.price === 0 ? <HorizontalRuleIcon /> :
                    (
                      sortOption.price === 1 ? <ArrowDropUpTwoToneIcon /> :
                      <ArrowDropDownTwoToneIcon />
                    )
                  }>
                  가격
                </Button>
              </Grid>
          </Grid>
          <Grid container
            ref={refList.product_list}
            direction="row"
            item sx={{
              width:"100%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height:`calc(100% - ${componentHeight.filterTop-8}px)`, overflow:"scroll"}}>
            {isProductLoading ? (
              [1,2,3].map((el) => (
                <Grid item key={`product-loading-${el}`} xs={12} md={4} xl={3} sx={{paddingRight:"4px", paddingBottom:"4px"}}>
                  <CardLoadingView />
                </Grid>
              ))
            ) :
              products.length > 0 ? (
              products.map((el) => (
                ( viewType === PRODUCT_CARD_VIEW ) ?
                <Grid item key={`product-${el.key}`} xs={6} sm={4} md={3} xl={2} sx={{paddingRight:"4px", paddingBottom:"4px"}}>
                  <CardView
                    info={el}
                    onDelete={handleDeleteOpen(el.key)}
                    onStorage={handleStorageOpen(el.key)}
                    onImage={handleImageOpen(el.key)}
                    isSelected={selected.filter((item) => (item.key == el.key) ).length !== 0}
                    onSelect={handleProductSelect(el.key)}/>
                </Grid> :
                <Grid item key={`product-list-${el.key}`} xs={12} sm={12} md={12} xl={12} sx={{
                  padding:"4px 16px"}}>
                  <Box sx={{width:'100%',position:'relative'}}>
                    <ListView
                      info={el}
                      onDelete={handleDeleteOpen(el.key)}
                      onStorage={handleStorageOpen(el.key)}
                      onImage={handleImageOpen(el.key)}
                      isSelected={selected.filter((item) => (item.key == el.key) ).length !== 0}
                      onSelect={handleProductSelect(el.key)}/>
                  </Box>
                </Grid>
              ))
            ) : (<></>)}
            <Grid
              container
              item sx={12} md={12}
              direction="row"
              justifyContent="center"
              sx={{marginTop:'24px', marginBottom:'24px'}}
              alignItems="center">
              <Pagination count={
                totalNumber !== 0 ? Math.ceil(totalNumber / viewNumber) : 1
              } page={page} onChange={handlePageChange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{borderTopLeftRadius:"8px",
                borderTopRightRadius:"8px",
                position:"fixed",
                bottom:"0px",
                left : {xs : "0px", sm : "240px"},
                width:{xs : "100%", sm : "calc(100% - 256px)"},
                backgroundColor:blue[50],
                transition:'transform .3s',
                transform:( (panelExpanded == 0) ? "translateY(280px)" : (panelExpanded == 1) ? "translateY(220px)" : "translateY(0px)"),
                height:"280px"}}>
          <Box sx={{width:"100%", height:"60px"}}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{width:"calc(100% - 36px)", height:"60px", paddingLeft:"24px", paddingRight:"24px"}}>
              <Typography variant="p" component="span">
              {selected.length} 개
              </Typography>
              <Stack
                direction="row"
                sx={{marginLeft:"auto"}}
                justifyContent="center"
                alignItems="center"
                spacing={1}>
                <Button variant="outlined" onClick={handleBasketInitial} size="small">초기화</Button>
                <Button variant="contained" onClick={handleBasketUpload} size="small" disableElevation>업로드</Button>
                <IconButton onClick={handlePanelExpand}>
                  {(panelExpanded == 1) ? <KeyboardArrowUpTwoToneIcon/> : <KeyboardArrowDownTwoToneIcon/>}
                </IconButton>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{width:"100%", height:"220px", overflowX:"scroll", padding:"0px 16px 0px 16px"}}>
            <Stack
              sx={{minWidth:'100%', height:'220px', display:'block', whiteSpace:'nowrap', textAlign:"left"}}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}>

              {selected.length > 0 ? (
                selected.map((el) => (
                  <CardBasketView
                    item
                    key={`basket-view-container-${el.hpid}`}
                    info={el}/>
                ))
              ) : (<></>)}

            </Stack>
          </Box>
      </Box>
      <ProductUploadDialog
        open={isUploadOpen}
        onClose={handleUploadDialogClose}
        onUpload={handleUploadProduct}
        items={selected}/>
      <ProductImageDialog
        open={isImageOpen}
        onClose={handleImageDialogClose}
        name={(imageItem.name == null) ? "" : imageItem.name }
        images={imageItem.images} />
      <ProductStorageDialog
        open={isStorageOpen}
        onClose={handleStorageDialogClose}
        name={(storageItem.name == null) ? "" : storageItem.name }
        options={storageItem.options} />
      <DeleteConfirmDialog
        open={deleteOption.open}
        onCancel={handleDeleteClose}
        onDelete={handleDeleteSelect}/>
    </Box>
  );
}

export default MenuAdminPage;
