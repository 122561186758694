import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Typography
} from '@mui/material';


function EventAdminPage(props){

  const navigate = useNavigate();

  const handleEventAdd = () => {
      navigate("/admin/event/create");
  };

  return (
    <Box sx={{
      position:'relative',
      width:'100%',
      boxSizing:'border-box'
      }}>
      <Box sx={{
        position:'relative',
        width:'100%',
        padding:'36px',
        boxSizing:'border-box'
      }}>
      <Typography
        sx={{width:'100%', position:'relative', fontSize:'24px', fontWeight:'900', textAlign:'left'}}>
        이벤트 관리
      </Typography>
      <Button
        variant="outlined"
        onClick={handleEventAdd}
        sx={{
          position:'absolute',
          top:'36px',
          right:'36px',
          fontSize:'18px'
        }}>
        이벤트 추가
      </Button>
      </Box>
    </Box>
  );
}

export default EventAdminPage;
