import React, {useRef, useEffect, useState} from 'react';

import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Collapse from '@mui/material/Collapse';

import { pink, blue, deepOrange } from '@mui/material/colors';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import {
  MainMenuItems,
  TopMenuItems
} from '../../resources';

import { SnackbarProvider, useSnackbar } from 'notistack';

import css_main from '../../css/main.module.css';

const drawerWidth = 240;

function SignedLayoutInner(props) {
  const { window, type=1 } = props;
  
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [accordionOpen, setAccordionOpen] = React.useState({});

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [appBarHeight, setAppBarHeight] = useState(0);

  const appbarTop = useRef(null);

  useEffect(() => {
    setAppBarHeight(appbarTop.current.clientHeight);
  });

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => (event) => {
    setAnchorElUser(null);
    switch(setting){
      case "my_setting_profile" :
        navigate('/auth/account');
        break;
      case "my_setting_logout" :
        props.removeToken();
        navigate('/');
        break;
      default : break;
    }

  };

  const handleHomeClick = (event) => {
    navigate('/');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAccordionClick = (prop) => (event) => {
    setAccordionOpen({ ...accordionOpen, [prop]: !accordionOpen[prop] });
  };

  const handleLinkClick = (prop) => (event) => {
    // when page moved, navigation should be closed
    setMobileOpen(false);
    navigate(prop, {replace: true});
  }

  const myMenu = (
    <Box sx={{ flexGrow: 0 }}>
       <Menu
         sx={{ mt: '45px', zIndex:1200 }}
         id="menu-appbar"
         anchorEl={anchorElUser}
         anchorOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         keepMounted
         transformOrigin={{
           vertical: 'top',
           horizontal: 'right',
         }}
         open={Boolean(anchorElUser)}
         onClose={handleCloseUserMenu("")}
       >
         {TopMenuItems.map((setting) => (
           <MenuItem key={`top-menu-button-${setting.key}`} onClick={handleCloseUserMenu(setting.key)}>
             <Typography textAlign="center">{setting.name}</Typography>
           </MenuItem>
         ))}
       </Menu>
     </Box>
  );
  const drawer = (
    <Box  sx={{ textAlign: 'center' }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{mt:2, mb:2}}
        spacing={0}>
        <Typography variant="h6" sx={{ m: 0, color:blue[900], fontWeight:900 }}>
          <img
            onClick={handleHomeClick}
            style={{height:'18px', cursor:'pointer'}} src="/icons/eaudevie_italic_black.png" />
        </Typography>
        <Typography variant="p" sx={{
          fontFamily: 'Gowun Bold',
          m: 0, color:blue[900], fontSize:'12px' }}>
          Find your new favorite whisky
        </Typography>
      </Stack>
      <Divider />
      <List>
        {MainMenuItems.map((item) => (
          (item.type == 'accordion') ?
          <React.Fragment  key={`nav-drawer-fragment-${item.key}`} >
            <ListItemButton key={item.key} onClick={handleAccordionClick(item.key)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title} />
              {accordionOpen[item.key] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={accordionOpen[item.key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            {(item.children.length > 0) && item.children.map((_child) => (
              <ListItemButton
                key={_child.key}
                onClick={handleLinkClick(_child.to)}
                sx={{ pl: 4 }}>
                <ListItemIcon>
                  {_child.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontFamily: 'Gowun Bold !important',
                  }}
                  primary={_child.title} />
              </ListItemButton>
            ))}
            </List>
            </Collapse>
          </React.Fragment> :
          <ListItem key={item.key} disablePadding>
            <ListItemButton
              onClick={handleLinkClick(item.to)}
              sx={{ textAlign: 'left' }}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                sx={{
                  fontFamily: 'Gowun Bold !important',
                }}
                primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{height:'74px'}}></Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', height:"100vh" }}>
      <AppBar component="nav"
        id="back-to-top-anchor"
        ref={appbarTop}
        elevation={0}
        sx={{
          backgroundColor : (type === 0) ? "rgba(255,255,255,0)": "#ffffff",
          borderBottom:(type === 0) ? "0px": '1px solid #dddddd'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon
               sx={{ color: (type === 0) ? '#ffffff' : '#000000' }}/>
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color:(type === 0) ? "#ffffff" : "#222222", textAlign:'left', fontWeight:900, display: { xs: 'none', sm: 'block' } }}>
            {(type === 0) ?
              <img
                onClick={handleHomeClick}
                style={{height:'18px', cursor:'pointer'}} src="/icons/eaudevie_italic_white.png" /> :
              <img
                onClick={handleHomeClick}
                style={{height:'18px', cursor:'pointer'}} src="/icons/eaudevie_italic_black.png" />}
          </Typography>
          {myMenu}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: 'block',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          <Box
            sx={{position:'fixed',boxSizing:'border-box', bottom:'0px', width:drawerWidth, padding:2, backgroundColor:'#ffffff', borderTop:`1px solid ${blue[300]}`}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={0}>
              <Typography
                sx={{
                  fontFamily: 'Gowun Bold',
                  color:'#666666',
                  fontWeight:300, fontSize:'14px'}}>
                Gigto Link version v.0.1
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Gowun Bold',
                  color:'#666666',
                  fontWeight:300, fontSize:'12px'}}>
                업데이트 사항
              </Typography>
            </Stack>
          </Box>
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%", overflow:"hidden" }}>
        {(type !== 0) && <Toolbar />}
        <Box component="div" sx={{position:'relative', width:"100%", p:0, height:(type === 0) ? '100vh':`calc(100vh - ${appBarHeight}px)`}}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

NonSignedLayout.propTypes = {
  window: PropTypes.func,
};

export default function NonSignedLayout(props) {
  return (
    <SignedLayoutInner {...props}>
      {props.children}
    </SignedLayoutInner>
  );
}
