import * as React from 'react';
import PropTypes from 'prop-types';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

import RecommendItem from './RecommendItem';
import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  Box,
  Typography
} from '@mui/material';

const responsive = {
  desktop2: {
    breakpoint: { max: 3000, min: 1280 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 0
  },
  desktop: {
    breakpoint: { max: 1280, min: 900 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 120
  }
};

function RecommendCarousel(props){

  const { items } = props;

  return (
    <Carousel
      style={{width:'100%', height:'100%', position:'relative'}}
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={false}
      autoPlay={false}
      keyBoardControl={true}
      transitionDuration={300}
      partialVisible={true}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      deviceType={props.deviceType}
      dotListClass="custom-dot-list-style">
      { items.length > 0 && items.map((el) => (
        <RecommendItem
          item={el}
          />
      ))}
    </Carousel>
  );
}

export default RecommendCarousel;
