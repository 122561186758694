import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

function ScrollTop(props) {
  const { children, parent } = props;

  const trigger = useScrollTrigger({
    //target: window ? window() : undefined,
    target: document.getElementById("MAIN-CONTAINER"),
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    console.log(anchor);

    if (anchor) {
      document.getElementById("MAIN-CONTAINER").scrollTo(0,0);
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 24, right: 24 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default ScrollTop;
