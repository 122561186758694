import { applyMiddleware, compose, createStore } from 'redux'
import { thunk as thunkMiddleware } from 'redux-thunk';

import monitorReducersEnhancer from '../enhancers/monitorReducer'
import loggerMiddleware from '../middleware/logger'
import { rootReducer } from '../reducers';

export default function configureStore(preloadedState) {
  const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
  const composedEnhancer = composedEnhancers(...enhancers)

  //const store = createStore(rootReducer, preloadedState, composedEnhancers)
  const store = createStore(rootReducer, composedEnhancer)
  return store
}
