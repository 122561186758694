import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditorInput(props) {
  const {
    value, onChange
  } = props;

  return <ReactQuill
    style={{
      width:'100%',
      position:'relative',
      height:'100%'
    }}
    theme="snow" value={value} onChange={onChange} />;
}

export default EditorInput;
