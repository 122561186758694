import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Stack,
  ImageList,
  ImageListItem
} from '@mui/material';

function EventPage(props){

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box', overflow:'scroll' }}>
    </Box>
  );
}


export default EventPage;
