import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CardMedia from '@mui/material/CardMedia';
// icon list
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
// heartit controller
import LikeController from '../../apis/products/LikeController';

import LiquorTwoToneIcon from '@mui/icons-material/LiquorTwoTone';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';

import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

export default function ListView(props){

  const { enqueueSnackbar } = useSnackbar();
  const { info, onDelete } = props;

  const navigate = useNavigate();
  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const getTimestamp = (id) => {
    let timestamp = id.toString().substring(0,8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return moment(date).fromNow();
  }

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }
  const handleShowDetail = (event) => {
    navigate(`/admin/product/${info.key}`);
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      onClick={handleShowDetail}
      sx={{width:'100%', borderBottom:'1px solid #eeeeee', padding:'12px 0px', backgroundColor:(info.active ? "#ffffff" : "#eeeeee")}}>
      <Grid item xs md={2}>
        <Box sx={{position:'relative', width:'100%', height:'84px'}}>
          <CardMedia
            component="img"
            height="84"
            image={info.images.length > 0 ? `/api/product/image/${info.images[0]}` : "" }
            sx={{objectFit:"contain"}}
            alt={`product-images-${info.title.kr}`}
          />
          <Box sx={{position:'absolute', bottom:'12px', right:'12px'}}>
            <Chip icon={<ImageTwoToneIcon size="small" />} size="small" label={info.images.length} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md>
        <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
          <Tooltip title={info.description.type}>
            <Chip label={info.description.type} size="small" sx={{marginBottom:'4px', fontSize:'12px'}} />
          </Tooltip>
          {!info.active && <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '12px',
            mb:'2px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            }}>
            <a style={{textDecoration:'none', color:'#ff4444'}}>노출 안함</a>
          </Typography>}
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '14px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            }}>
            <a style={{textDecoration:'none', color:'#222222', fontSize:"16px", fontWeight:900, mb:'8px'}}>{info.title.kr}</a>
          </Typography>

          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:500}}>
            <LiquorTwoToneIcon sx={{fontSize:'12px', mr:'6px'}} />
            {(new Intl.NumberFormat('ko', { style: 'currency', currency: 'krw' }).format(info.price.shot))}
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:500}}>
            <LocalBarTwoToneIcon sx={{fontSize:'12px', mr:'6px'}}/>
            {(new Intl.NumberFormat('ko', { style: 'currency', currency: 'krw' }).format(info.price.bottle))}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            }}>
            {info.description.short}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
