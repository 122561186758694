import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LiquorTwoToneIcon from '@mui/icons-material/LiquorTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import NotesIcon from '@mui/icons-material/Notes';

import moment from 'moment';

import{
  Grid,
  Button,
  Container,
  Stack,
  Paper
} from '@mui/material';

import MainEventCard from '../../components/home/MainEventCard';
import Footer from '../../components/common/Footer';

import EventController from '../../apis/events/EventController';

import { blue, amber, orange, deepOrange } from '@mui/material/colors';

const drawerWidth = 240;
moment.locale('kr');

function AbooutPage(props) {

  const navigate = useNavigate();


  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box', overflow:'scroll' }}>
      <Box sx={{width:'100%', position:'relative', height:{xs:'60vh',md:'80vh'}}}>
        <img
          style={{width:'100%', position:'relative', height:'100%', objectFit:'cover', filter:'brightness(50%)'}}
          src="/images/eaudevie_image.jpg" />
        <Stack
          sx={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px'}}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0}>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            padding:'0px 24px',
            textAlign:"center", color:'#dddddd', fontSize:'28px', fontWeight:'500', maxWidth:'720px' }}>
            SINCE 2018
          </Typography>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            padding:'0px 24px',
            textAlign:"center", color:'#ffffff', fontSize:'36px', fontWeight:'900', maxWidth:'720px' }}>
            위스키와 시간이 머무는 곳, Eaudevie
          </Typography>
        </Stack>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Container sx={{width:'100%', position:'relative'}}>
            <Grid
              container
              spacing={2}
              sx={{width:'100%', justifyContent: "center", alignItems: "center"}}>
              <Grid item xs={12} sm={12} md={12} sx={{position:'relative'}}>
                <Typography element="p" sx={{
                  fontFamily: 'Gowun Bold',
                  padding:'36px 24px',
                  textAlign:"center", color:'#444444', fontSize:'24px', fontWeight:'500' }}>
                  2018년에 오픈한 Eaudevie는 위스키 애호가와 입문자 모두를 위한 특별한 공간입니다.
                  300 여종의 다양한 스피릿과 다채로운 칵테일을 착한 가격으로 만나 보실 수 있습니다.
                  Eaudevie는 위스키를 사랑하는 사람이라면 꼭 들러봐야 할, 품격 있는 시간을 선사하는 곳입니다.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Footer/>
    </Box>
  );
}

AbooutPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default AbooutPage;
