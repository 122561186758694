import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Typography,
  Stack,
  Chip
} from '@mui/material';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

function RecommendItem(props){

  const { item } = props;

  const navigate = useNavigate();

  const handleMenuItemClick = () => {
    navigate('/item/'+item.key);
    navigate(0);
  };

  return (
    <Box sx={{width:'220px', position:'relative', padding:'0px 20px 0px 0px'}}
      onClick={handleMenuItemClick}>
      <Box sx={{position:'relative', width:'100%', paddingBottom:'100%', mb:'12px'}}>
        <img src={item.images.length > 0 ? `/api/product/image/${item.images[0]}` : "/images/whiskey2.jpg" }
          style={{
          position:'absolute',
          top:'0px', left:'0px', right:'0px', bottom:'0px', width:'100%', height:'100%',
          cursor:'pointer',
          borderRadius:'16px', objectFit:'cover'
        }}/>
      </Box>
      <Typography element="p" sx={{textAlign:"left", fontWeight:900, mb:'12px', fontSize:'20px'}}>
        {item && item.title.kr}
      </Typography>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}>
          <WineBarTwoToneIcon
            fontSize="small"/>
          <Typography element="p" sx={{textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
            {item && item.price.shot.toLocaleString()}
          </Typography>
        </Stack>
        {item && item.price.bottle > 0 && <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{marginBottom:'12px'}}
          spacing={2}>
          <LiquorIcon
            fontSize="small"/>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
            {item && item.price.bottle.toLocaleString()}
          </Typography>
        </Stack>}
        <Typography
          sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              textAlign:'left',
              lineHeight:2,
              WebkitLineClamp: 2,
          }}>
          {
            (item.tags.length > 0) &&
            item.tags.map((el) => (
              (el.length > 0) && <Chip size="small" component="span" sx={{mr:'2px'}} icon={<TagIcon />} label={el} />
            ))
          }
        </Typography>
      </Stack>
    </Box>
  );
}

export default RecommendItem;
