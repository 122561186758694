import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LiquorTwoToneIcon from '@mui/icons-material/LiquorTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import NotesIcon from '@mui/icons-material/Notes';

import moment from 'moment';

import{
  Grid,
  Button,
  Container,
  Stack,
  Paper
} from '@mui/material';

import MainEventCard from '../../components/home/MainEventCard';
import Footer from '../../components/common/Footer';

import EventController from '../../apis/events/EventController';

import { blue, amber, orange, deepOrange } from '@mui/material/colors';

const drawerWidth = 240;
moment.locale('kr');

function HomePage(props) {

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  const handleMenuClick = () => {
    navigate('/menu');
  };

  const handleReservationClick = () => {
    navigate('/reservation');
  };

  const getEventSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      setEvents((prev) => (response.data.data));
    }
  };

  const getEventError = (error) => {

  };

  useEffect(() => {
    EventController.getEventFront({}, getEventSuccess, getEventError);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative',  boxSizing:'border-box', overflow:'scroll' }}>
      {/*<Box sx={{width:'100%', position:'fixed', top:'0px', left:'0px'}}>
        <Toolbar />
        <Box sx={{width:'100%', backgroundColor:teal[700], p:'6px 32px'}}>
          <Typography variant="h6" componant="p" sx={{textAlign:"left", color:'#ffffff', fontSize:'14px'}}>
            <Typography
              onClick={handlePreviousVersion}
              variant="p"
              componant="span"
              sx={{textAlign:"left", color:'#ffffff', fontSize:'14px', mr:'12px', cursor:'pointer'}}>
              기존 버전 으로 돌아가기
            </Typography>
            ( 안정화 전까지 기존 버전이 운영됩니다 )
          </Typography>
        </Box>
      </Box>*/}
      <Box sx={{width:'100%', position:'relative', height:{xs:'580px',md:'80vh'}}}>
        <img
          style={{width:'100%', position:'relative', height:'100%', objectFit:'cover', filter:'brightness(50%)'}}
          src="/images/whiskey2.jpg" />
        <Stack
          sx={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px'}}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Typography element="p" sx={{
            fontFamily: 'Gowun Bold',
            padding:'12px 24px',
            textAlign:"center", color:'#ffffff', fontSize:'36px', fontWeight:'900', maxWidth:'720px' }}>
            Find your new favorite whisky at Eau de Vie.
          </Typography>
        </Stack>
        <Paper elevation={8} sx={{
          width: 'calc(100% - 48px)',
          maxWidth:'720px', bottom:'0px',
          left:'50%', position:'absolute',
          padding:'24px 0px',
          zIndex:1,
          transform:'translate(-50%, 50%)',
          backgroundColor:'#222222',
          borderRadius:'16px'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{width:'100%', padding:'16px'}}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}>
                  <LiquorTwoToneIcon sx={{color:'#ffffff', fontSize:"36px"}}/>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>
                    <Typography sx={{
                      fontFamily: 'Gowun Bold',
                      fontSize:'16px', color:'#eeeeee', lineHeight:1.2, fontWeight:900}}>
                      오드비에서 다양한
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Gowun Bold',
                      fontSize:'16px', color:'#eeeeee', lineHeight:1.2, mt:'8px !important', fontWeight:900}}>
                      위스키를 찾아보세요
                    </Typography>
                    <Button
                      sx={{
                        fontFamily: 'Gowun Bold',
                        border:'1px solid #ffffff',
                        color:'#ffffff'}}
                      size="small"
                      onClick={handleMenuClick}
                      variant="outlined" endIcon={<ArrowForwardIcon />}>
                      위스키 찾기
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{width:'100%', padding:'16px'}}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}>
                  <StorefrontTwoToneIcon sx={{color:'#ffffff', fontSize:"36px"}}/>
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>
                    <Typography sx={{
                      fontFamily: 'Gowun Bold',
                      fontSize:'16px', color:'#eeeeee', lineHeight:1.2, fontWeight:900}}>
                      모임이 있으신가요?
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Gowun Bold',
                      fontSize:'16px', color:'#eeeeee', lineHeight:1.2, mt:'8px !important', fontWeight:900}}>
                      매장을 대관해보세요
                    </Typography>
                    <Button
                      sx={{
                        fontFamily: 'Gowun Bold',
                        border:'1px solid #ffffff', color:'#ffffff'}}
                      size="small"
                      onClick={handleReservationClick}
                      variant="outlined" endIcon={<ArrowForwardIcon />}>
                    예약하기
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Container sx={{width:'100%', position:'relative'}}>
            <Grid
              container
              spacing={2}
              sx={{width:'100%'}}>
              <Grid item xs={12} sm={12} md={12} sx={{height:{xs:'180px', sm:'180px', md:'120px'}, position:'relative'}}>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" element="p" sx={{
                  fontFamily: 'Gowun Bold',
                  textAlign:"center", mb:'24px', fontWeight:900, fontSize:'32px'}}>
                  이벤트
                </Typography>
              </Grid>
              {events ? events.map((el) => (
                <MainEventCard />
              )) :
              (
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h6" element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"center", mb:'72px', fontWeight:900, fontSize:'28px', color:blue[800]}}>
                    현재 진행 중인 이벤트가 없습니다
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </Box>
      <Footer/>
    </Box>
  );
}

HomePage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default HomePage;
