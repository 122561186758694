import React from 'react';
import { Routes, Route, Switch, Navigate, useNavigate, useLocation } from 'react-router-dom';
import useToken from '../utils/useToken'
import axios from 'axios';

import {
  DashboardPage,
  MenuAdminPage,
  MenuAdminUpdatePage,
  MenuAdminCreatePage,

  EventAdminPage,
  EventAdminCreatePage,

  QueueLayout,
  SignedLayout,
  SignedSubLayout,

  LoginPage,
  FindPasswordPage,
  HomePage,
  AboutPage,
  MenuPage,
  MenuSearchPage,
  ItemPage,
  RedirectPage,

  EventPage,

  NoticeContentPage,
  NoticePage,

  ReservationPage,

  NonSignedLayout,
  NonSignedSubLayout,
  NonNothingLayout

} from '../containers'

export default function SignedRoute(props){

  const { token, removeToken, setToken } = useToken();
  const navigate = useNavigate();
  const { state } = useLocation();
  // errorComposer will compose a handleGlobally function
  const errorComposer = (error) => {

      return () => {
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 404) {

          }

          if (statusCode === 401) {
            props.onRemoveToken();
          }
      }
  }

  axios.interceptors.response.use(undefined, function (error) {
      error.handleGlobally = errorComposer(error);

      return Promise.reject(error);
  })

  if (state) {
      navigate(state);
  }

  return (
    <Routes>
      <Route path='/dashboard' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/menu' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <MenuAdminPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/product/:id' element={
        <QueueLayout>
          <SignedSubLayout title="상품 정보 수정하기" removeToken={removeToken}>
            <MenuAdminUpdatePage {...props} token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/admin/product/create' element={
        <QueueLayout>
          <SignedSubLayout title="상품 추가하기" removeToken={removeToken}>
            <MenuAdminCreatePage {...props} token={props.token} setToken={props.setToken} />
          </SignedSubLayout>
        </QueueLayout>
        } />
      <Route path='/admin/events' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <EventAdminPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/event/create' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <EventAdminCreatePage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/notices' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />
      <Route path='/admin/reservation' element={
        <QueueLayout>
          <SignedLayout removeToken={removeToken}>
            <DashboardPage {...props} token={props.token} setToken={props.setToken} />
          </SignedLayout>
        </QueueLayout>
        } />

        <Route path='/' element={
          <QueueLayout>
            <NonSignedLayout type={0}>
              <HomePage token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/about' element={
          <QueueLayout>
            <NonSignedLayout type={0}>
              <AboutPage token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/menu' element={
          <QueueLayout>
            <NonSignedLayout>
              <MenuPage {...props} token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/menu/search' element={
          <QueueLayout>
            <NonSignedLayout>
              <MenuSearchPage {...props} token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/item/:id' element={
          <QueueLayout>
            <NonSignedSubLayout>
              <ItemPage token={props.token} setToken={props.setToken} />
            </NonSignedSubLayout>
          </QueueLayout>
        } />
        <Route path='/events' element={
          <QueueLayout>
            <NonSignedLayout>
              <EventPage token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/event/:id' element={
          <QueueLayout>
            <NonSignedSubLayout>
              <ItemPage token={props.token} setToken={props.setToken} />
            </NonSignedSubLayout>
          </QueueLayout>
        } />
        <Route path='/notices' element={
          <QueueLayout>
            <NonSignedLayout>
              <NoticePage token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/notice/:id' element={
          <QueueLayout>
            <NonSignedSubLayout>
              <NoticeContentPage token={props.token} setToken={props.setToken} />
            </NonSignedSubLayout>
          </QueueLayout>
        } />
        <Route path='/signin' element={
          <QueueLayout>
            <NonNothingLayout>
              <LoginPage token={props.token} setToken={props.setToken} />
            </NonNothingLayout>
          </QueueLayout>
        } />
        <Route path='/reservation' element={
          <QueueLayout>
            <NonSignedLayout>
              <ReservationPage token={props.token} setToken={props.setToken} />
            </NonSignedLayout>
          </QueueLayout>
        } />
        <Route path='/forward/youtube' element={
          <RedirectPage
            loc="https://www.youtube.com/@Eaudevie_whiskey"/>
        } />
        <Route path='/forward/instagram' element={
          <RedirectPage
            loc="https://www.instagram.com/eaudevie8"/>
        } />
        <Route path='*' element={<Navigate to="/" />} />
    </Routes>
  )
}
