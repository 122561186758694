import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Stack,
  ImageList,
  ImageListItem,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/ko'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';

import EditorInput from '../../components/inputs/EditorInput';

moment.locale('ko')

function EventAdminCreatePage(props){

  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [previousPreviews, setPreviousPreviews] = useState([]);

  const [editorText, setEditorText] = useState('');
  const [eventData, setEventData] = useState({
    title : '',
    description : '',
    range_start : moment(),
    range_end : moment()
  });

  const { enqueueSnackbar } = useSnackbar();
  
  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleEventTitle = (event) => {
    setEventData((prev) => ({...prev, title:event.target.value}))
  };

  const handleEventDescription = (event) => {
    setEventData((prev) => ({...prev, description:event.target.value}))
  };

  const handleEventStartDate = (value) => (event) => {
    setEventData((prev) => ({...prev, range_start :event.target.value}))
  };

  const handleEventEndDate = (value) => (event) => {
    setEventData((prev) => ({...prev, range_end :event.target.value}))
  };

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.readAsDataURL(file);
    })
  };

  const handleDeleteImage = (prev, item) => {

      if(prev){
        const _prev = [...previousPreviews];
        const _previousPreviews = _prev.filter((_item) => _item !== item);
        setPreviousPreviews((pre) => _previousPreviews);
      }else{
        const _prev = [...previews];
        const _previews = _prev.filter((_item) => _item.key !== item.key);
        setPreviews((pre) => _previews);
      }
  };


  return (
    <Box sx={{
      position:'relative',
      width:'100%',
      textAlign:'left',
      boxSizing:'border-box'}}>
      <Grid container
        sx={{width:'100%', position:'relative', padding:'36px'}}>
        <Grid item md={12}
          sx={{boxSizing:'border-box', padding:'16px'}}>
          <Typography
            sx={{
              fontSize:'24px',
              marginBottom:'16px',
              fontWeight:'900', textAlign:'left'}}>
            새로운 이벤트 추가하기
          </Typography>
        </Grid>
        <Grid item
          md={6}>
          <Box sx={{width:'100%', boxSizing:'border-box', padding:'16px'}}>
            <Typography
              sx={{
                fontSize:'18px',
                marginBottom:'16px',
                fontWeight:'900', textAlign:'left'}}>
              이벤트 제목
            </Typography>
            <TextField
              required
              id="outlined-required"
              sx={{maxWidth:'420px', width:'100%', position:'relative'}}
              label="제목"
              onChange={handleEventTitle}
              value={eventData.title}/>
          </Box>
          <Box sx={{width:'100%', boxSizing:'border-box', padding:'16px'}}>
            <Typography
              sx={{
                fontSize:'18px',
                marginBottom:'16px',
                fontWeight:'900', textAlign:'left'}}>
              짧은 설명
            </Typography>
            <TextField
              required
              id="outlined-required"
              sx={{maxWidth:'420px', width:'100%', position:'relative'}}
              label="부제"
              multiline
              rows={3}
              onChange={handleEventDescription}
              value={eventData.description}/>
          </Box>
          <Box sx={{width:'100%', boxSizing:'border-box', padding:'16px'}}>
            <Typography
              sx={{
                fontSize:'18px',
                marginBottom:'16px',
                fontWeight:'900', textAlign:'left'}}>
              대표 이미지
            </Typography>
            <ImageUploader
              value={images}
              onChange={handleImageChange}
              sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
              {(previews.length || previousPreviews.length) > 0 && (
                <>
                  <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                    업로드 이미지 수 : {previews.length + previousPreviews.length}
                  </Typography>
                  <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                    {previousPreviews.map((item) => (
                      <ImageListItem key={item}>
                        <img
                          src={`/api/product/image/${item}`}
                          srcSet={`/api/product/image/${item}`}
                          alt={"previous-image"}
                          loading="lazy"
                          style={{
                            objectFit:'cover',
                            width:'100%',
                            height:'100%',
                            position:'relative'
                          }}
                        />
                        <IconButton
                          onClick={() => { handleDeleteImage(true, item); }}
                          sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </ImageListItem>
                    ))}
                    {previews.map((item) => (
                      <ImageListItem key={item.key}>
                        <img
                          src={item.url}
                          srcSet={item.url}
                          alt={item.title}
                          loading="lazy"
                        />
                        <IconButton
                          onClick={() => { handleDeleteImage(false, item); }}
                          sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              )}
          </Box>
          <Box sx={{width:'100%', boxSizing:'border-box', padding:'16px'}}>
            <Typography
              sx={{
                fontSize:'18px',
                marginBottom:'16px',
                fontWeight:'900', textAlign:'left'}}>
              이벤트 기간
            </Typography>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}>
              <DatePicker
                 label="이벤트 시작일"
                 renderInput={(params) => <TextField {...params} />}
                 value={eventData.range_start}
                />
              <DatePicker
                 label="이벤트 종료일"
                 renderInput={(params) => <TextField {...params} />}
                 value={eventData.range_end}
                />
            </Stack>
          </Box>
        </Grid>
        <Grid item
          md={6}
          sx={{padding:'16px'}}>
          <Typography
            sx={{fontSize:'18px', fontWeight:'900', textAlign:'left'}}>
            이벤트 내용
          </Typography>
          <Box sx={{
            width:'100%',
            position:'relative',
            boxSizing:'border-box',
            padding:'16px 0px', height:'500px'}}>
            <EditorInput
              value={editorText}
              onChange={setEditorText}/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventAdminCreatePage;
