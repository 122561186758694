import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  Box
} from '@mui/material';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

function ItemCarousel(props){

  const { images } = props;

  return (
    <Carousel
      style={{width:'100%', height:'100%', position:'relative'}}
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={false}
      keyBoardControl={true}
      transitionDuration={300}
      containerClass="carousel-container"
      deviceType={props.deviceType}
      dotListClass="custom-dot-list-style">
      {(images.length > 0) ? (
        images.map((el) => (
          <Box sx={{width:'100%', backgroundColor:'#214321', height:{md:'calc(100vh - 64px)', sm:'100vw', xs:'100vw'}, position:'relative'}}>
            <img
              style={{
                width:'100%',
                height:'100%',
                position:'absolute',
                top:'0px', left:'0px', right:'0px', bottom:'0px',
                objectFit:'cover'
              }}
              alt="test"
              src={`/api/product/image/${el}`} />
          </Box>
        ))
      ) : (
        <Box sx={{width:'100%', backgroundColor:'#214321', height:{md:'calc(100vh - 64px)', sm:'100vw', xs:'100vw'}, position:'relative'}}>
          <img
            style={{
              width:'100%',
              height:'100%',
              position:'absolute',
              top:'0px', left:'0px', right:'0px', bottom:'0px',
              objectFit:'cover'
            }}
            src="/images/whiskey2.jpg" />
        </Box>
      )}
    </Carousel>
  );
}

export default ItemCarousel;
