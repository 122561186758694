import axios from 'axios';
import getToken from '../../utils/getToken'

export default class ReservationController{
  static setReservation(data, _success, _error){
    //const token = getToken();
    axios({
      method: "POST",
      url : `/api/reservation/create`,
      data : data
    }).then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static updateReservation(data, _success, _error){
    //const token = getToken();
    axios({
      method: "UPDATE",
      url : `/api/reservation/update`,
      data : data
    }).then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getReservation(_success, _error){
    //const token = getToken();
    axios({
      method: "GET",
      url : `/api/reservation`,
    }).then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getReservationAdmin(_success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url : `/api/reservation/admin`,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    }).then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
}
