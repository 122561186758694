import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

//  heartit view
import DOOutLinkRowView from '../../components/daily/DOOutLinkRowView';
// controller
import ProductController from '../../apis/products/ProductController';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';
import TypeInput from '../../components/inputs/TypeInput';
import DOTypeSelectorView from '../../components/inputs/DOTypeSelectorView';

// heartit dialog
import ProductOptionDialog from '../../components/dialogs/ProductOptionDialog';
import ProductLinkDialog from '../../components/dialogs/ProductLinkDialog';

import {
  CategoryList
} from '../../resources';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

import { NumericFormat } from 'react-number-format';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator=','
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function MenuAdminUpdatePage(props){

  const params = useParams();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [previousPreviews, setPreviousPreviews] = useState([]);
  const [price, setPrice] = React.useState('');
  const [typeList, setTypeList] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const [isExpose, setisExpose] = React.useState(true);

  const handleIsExposeChange = (event) => {
    setisExpose(event.target.checked);
  };

  const [product, setProduct] = useState({
    title : "",
    short : "",
    type : "",
    description : "",
    option_s : "",
    option_t : "",
    option_f : "",
    comment : "",
    price_bottle : 0,
    price_shot : 0
  })
  const [error, setError] = useState({
    title : false,
    short : false,
    type : false,
    description : false,
    option_s : false,
    option_t : false,
    option_f : false,
    comment : false,
    price_bottle : false,
    price_shot : false
  });

  const [platformLinks, setPlatformLinks] = useState([]);

  const [productOptionDialogOpen, setProductOptionDialogOpen] = useState(false);
  const [productLinkDialogOpen, setProductLinkDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleInput = (type) => (event) => {
    setProduct((prev) => ({ ...prev, [type] : event.target.value }))
  }


  const handleTypeChange = (event, newValue) => {
    setTypeList((prev) => (newValue));
  };

  const handleOpenOptionDialog = (event) =>  {
    setProductOptionDialogOpen(true);
  }

  const handleProductOptionDialogClose = () => {
    setProductOptionDialogOpen(false);
  }

  const handleOpenLinkDialog = (event) =>  {
    setProductLinkDialogOpen(true);
  }

  const handleProductLinkDialogClose = () => {
    setProductLinkDialogOpen(false);
  }

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.readAsDataURL(file);
    })
  };

  const handleRegister = (event) => {
    setIsUploading(true);
    let _error = false;
    if(product.title.length < 1){
      setError((_prev) => ({ ..._prev, title : true}));
      _error = true;
    }
    if(product.description.length < 1){
      setError((_prev) => ({ ..._prev, description : true}));
      _error = true;
    }
    if(product.comment.length < 1){
      setError((_prev) => ({ ..._prev, comment : true}));
      _error = true;
    }
    if(product.short.length < 1){
      setError((_prev) => ({ ..._prev, short : true}));
      _error = true;
    }
    if(product.option_s.length < 1){
      setError((_prev) => ({ ..._prev, option_s : true}));
      _error = true;
    }
    if(product.option_t.length < 1){
      setError((_prev) => ({ ..._prev, option_t : true}));
      _error = true;
    }
    if(product.option_f.length < 1){
      setError((_prev) => ({ ..._prev, option_f : true}));
      _error = true;
    }
    if(product.price_shot.length < 1){
      setError((_prev) => ({ ..._prev, price_shot : true}));
      _error = true;
    }
    if(product.price_bottle.length < 1){
      setError((_prev) => ({ ..._prev, price_bottle : true}));
      _error = true;
    }
    if(Object.keys(typeList).length === 0){
      setError((_prev) => ({ ..._prev, type : true}));
      _error = true;
    }
    if(_error) return;
    //console.log("data",data);
    const data = {
      id: params.id,
      title : product.title,
      description : product.description,
      comment : product.comment,
      short : product.short,
      option_s : product.option_s,
      option_t : product.option_t,
      option_f : product.option_f,
      price_shot : product.price_shot,
      price_bottle : product.price_bottle,
      valid : isExpose,
      type : typeList.key,
      images : {
        origin : previousPreviews,
        update : previews.map((preview) => ({
          name : preview.title,
          file : preview.file
        }))
      }
    };

    ProductController.updateProduct(data, uploadProductSuccess, uploadProductError);
  }

  /*const handleProductOptionDialogData = (colorString, sizeString) => {
    let new_color = [...colors];
    const _colors = colorString.split(",");
    _colors.forEach((color) => {
      if(color.trim().length > 0){
        if (new_color.indexOf(color.trim()) === -1){
          handleSnackBar(`색상 [${color}]가 추가되었습니다`,'success');
          new_color.push(color.trim());
          //setColors([...colors, color.trim()]);
        }else{
          handleSnackBar(`색상 [${color}]가 이미 존재합니다.`,'error');
        }
      }
    });
    //setColors(new_color);

    let new_size = [...sizes];
    const _sizes = sizeString.split(",");
    _sizes.forEach((size) => {
      if(size.trim().length > 0){
        if (new_size.indexOf(size.trim()) === -1){
          handleSnackBar(`사이즈 [${size}]가 추가되었습니다`,'success');
          new_size.push(size.trim());
        }else{
          handleSnackBar(`사이즈 [${size}]가 이미 존재합니다.`,'error');
        }
      }
    });
    //setSizes(new_size);
    if(new_color.length === 0){
      new_color = ['U']
    }
    if(new_size.length === 0){
      new_size = ['U']
    }
    updateOption(new_color, new_size);
    setProductOptionDialogOpen(false);
  }*/

  const handleProductLinkDialogData = (platform, platformLink) => {
    setPlatformLinks((prev) => [...prev, { id : (Math.random() + 1).toString(36).substring(2), name : platform, url : platformLink, added : true}]);
  }

  const handleCancel = (event) => {
    navigate(-1);
  }

  const handleIntialize = (event) => {

  }

  /*const handleDeleteColor = (color) => (event) => {
    let _colors = [...colors];
    _colors.splice(_colors.indexOf(color.trim()), 1);
    setColors(_colors);
  };

  const handleDeleteOption = (option) => (event) => {
    const _options = [...options];
    const filtered = _options.filter((item) => item.key !== option.key);
    setOptions((prev) => filtered);
  };

  const handleDeleteSize = (size) => (event) => {
    let _sizes = [...sizes];
    _sizes.splice(_sizes.indexOf(size.trim()), 1);
    setSizes(_sizes);
  };*/

  const uploadProductSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      handleSnackBar("성공적으로 상품이 수정되었습니다", "success");
      navigate(-1);
    }
    setIsUploading(false);
  }

  const uploadProductError = (error) => {
    console.log(error);
    setIsUploading(false);
  }

  const getProductSuccess = (response) => {
    if(response.data.code === 200){
      const data = response.data.product;

      const type = CategoryList.filter((el) => el.key === data.description.type)[0];
      if(type !== undefined)
        setTypeList((prev) => (type));
      setPreviousPreviews(data.images);
      setisExpose(data.active);
      setProduct((prev) => ({
        ...prev,
        title : data.title.kr,
        short : data.description.short,
        type : type,
        description : data.description.description,
        option_s : data.description.specification[0].d,
        option_t : data.description.specification[1].d,
        option_f : data.description.specification[2].d,
        comment : data.description.comment,
        price_bottle : data.price.bottle,
        price_shot : data.price.shot
      }));

    }
  };

  const handleDeletePlatform = (item) => (event) => {
    const _platform = [ ...platformLinks ];
    const _newPlatform = _platform.filter((_item) => _item.id !== item.id );
    setPlatformLinks(_prev => _newPlatform);
  };

  const getProductError = (error) => {

  };

  const handleDeleteImage = (prev, item) => {

      if(prev){
        const _prev = [...previousPreviews];
        const _previousPreviews = _prev.filter((_item) => _item !== item);
        setPreviousPreviews((pre) => _previousPreviews);
      }else{
        const _prev = [...previews];
        const _previews = _prev.filter((_item) => _item.key !== item.key);
        setPreviews((pre) => _previews);
      }
  };

  const optionToString = (obj) => {
    return `${obj.size}-${obj.color}`;
  }

  useEffect(() => {
    ProductController.getProductAdmin({id : params.id}, getProductSuccess, getProductError);
  }, []);

  useEffect(() => {

  }, [previews]);
  /*const updateOption = (color, size) => {
    if(size.length > 0 || color.length > 0){
      let newOptions = [];
      size.forEach((size, _i1) => {
        color.forEach((color, _i2) => {
          newOptions.push({
            key : (Math.random() + 1).toString(36).substring(2),
            added : true,
            option : {
              size: size,
              color: color
            }
          });
        })
      });

      let uniqueObjArray = [...new Map([...options, ...newOptions].map((item) => [optionToString(item["option"]), item])).values()];

      setOptions(prev => uniqueObjArray);
    }
  }*/

  return (
    <Box sx={{width:{md : '100%', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Grid container sx={{position:'relative', width:'100%'}} spacing={2}>
        <Grid item md={6}>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              상품 명
            </Badge>
          </Typography>
          <TextField
            id="do-product-name"
            hiddenLabel
            disabled={isUploading}
            value={product.title}
            onChange={handleInput("title")}
            placeholder="상품명"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            대표 이미지
          </Typography>
          <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
            <ImageUploader
              value={images}
              onChange={handleImageChange}
              sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
              {(previews.length || previousPreviews.length) > 0 && (
                <>
                  <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                    업로드 이미지 수 : {previews.length + previousPreviews.length}
                  </Typography>
                  <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                    {previousPreviews.map((item) => (
                      <ImageListItem key={item}>
                        <img
                          src={`/api/product/image/${item}`}
                          srcSet={`/api/product/image/${item}`}
                          alt={"previous-image"}
                          loading="lazy"
                          style={{
                            objectFit:'cover',
                            width:'100%',
                            height:'100%',
                            position:'relative'
                          }}
                        />
                        <IconButton
                          onClick={() => { handleDeleteImage(true, item); }}
                          sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </ImageListItem>
                    ))}
                    {previews.map((item) => (
                      <ImageListItem key={item.key}>
                        <img
                          src={item.url}
                          srcSet={item.url}
                          alt={item.title}
                          loading="lazy"
                        />
                        <IconButton
                          onClick={() => { handleDeleteImage(false, item); }}
                          sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              )}
          </Box>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              위스키 종류
            </Badge>
          </Typography>
          <TypeInput
            sx={{width:'100%', maxWidth:'400px',mb:'24px'}}
            value={typeList} onChange={handleTypeChange}
            disabled={isUploading}
            multiple={false}/>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                width:'100%', maxWidth:'400px',mb:'24px',
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
                <Badge color="error" variant="dot">
                  제품 노출 여부
                </Badge>
              </Typography>
              <Switch
                checked={isExpose}
                onChange={handleIsExposeChange}
                inputProps={{ 'aria-label': 'controlled' }}/>
            </Stack>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              가격 설정
            </Badge>
          </Typography>
          <TextField
            id="do-product-code"
            hiddenLabel
            placeholder="샷 가격"
            disabled={isUploading}
            name="numberformat"
            value={product.price_shot}
            onChange={handleInput("price_shot")}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">원</InputAdornment>,
              inputComponent: NumberFormatCustom,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'12px'}}
            size="small"
            variant="outlined"/>
          <TextField
            id="do-product-bottle"
            hiddenLabel
            placeholder="보틀 가격"
            disabled={isUploading}
            name="numberformat"
            value={product.price_bottle}
            onChange={handleInput("price_bottle")}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">원</InputAdornment>,
              inputComponent: NumberFormatCustom,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'12px'}}
            size="small"
            variant="outlined"/>
          </Grid>
          <Grid item md={6}>
            <Box sx={{
              position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
                짧은 소개
              </Typography>
              <TextField
                  id="standard-multiline-static"
                  hiddenLabel
                  disabled={isUploading}
                  sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
                  placeholder="짧은 설명을 입력해주세요"
                  multiline
                  value={product.short}
                  onChange={handleInput("short")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={2}
                  variant="outlined"
                />
            </Box>
            <Box sx={{
              position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
                위스키 옵션
              </Typography>
              <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'6px', color:'#222222', fontWeight:700}}>
                노즈(향)
              </Typography>
              <TextField
                  id="standard-multiline-static"
                  hiddenLabel
                  disabled={isUploading}
                  sx={{width:'100%', maxWidth:'400px', mb:'8px'}}
                  placeholder="코(향)"
                  multiline
                  value={product.option_s}
                  onChange={handleInput("option_s")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={2}
                  variant="outlined"
                />
              <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'6px', color:'#222222', fontWeight:700}}>
                팔레트(맛)
              </Typography>
              <TextField
                  id="standard-multiline-static"
                  hiddenLabel
                  disabled={isUploading}
                  sx={{width:'100%', maxWidth:'400px', mb:'8px'}}
                  placeholder="팔레트(맛)"
                  multiline
                  value={product.option_t}
                  onChange={handleInput("option_t")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={2}
                  variant="outlined"
                />
              <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'6px', color:'#222222', fontWeight:700}}>
                피니시(후미)
              </Typography>
              <TextField
                  id="standard-multiline-static"
                  hiddenLabel
                  disabled={isUploading}
                  sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
                  placeholder="피니시(후미)"
                  multiline
                  value={product.option_f}
                  onChange={handleInput("option_f")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={2}
                  variant="outlined"
                />
            </Box>
            <Box sx={{
              position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
                본 설명
              </Typography>
              <TextField
                  id="standard-multiline-static"
                  hiddenLabel
                  disabled={isUploading}
                  sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
                  placeholder="본 설명을 입력해주세요"
                  multiline
                  value={product.description}
                  onChange={handleInput("description")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={4}
                  variant="outlined"
                />
            </Box>
            <Box sx={{
              position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
                Eauduevie's comment
              </Typography>
              <TextField
                  id="standard-multiline-static"
                  hiddenLabel
                  disabled={isUploading}
                  sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
                  placeholder="Eauduevie's comment"
                  multiline
                  value={product.comment}
                  onChange={handleInput("comment")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={4}
                  variant="outlined"
                />
            </Box>
          </Grid>
          <Grid item sm={12} md={12}>
            <Box sx={{width:'100%', mb:'32px'}}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{width:'100%'}}
                spacing={2}>
                <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">수정 취소</Button>
                <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
                <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">수정하기</Button>
              </Stack>
            </Box>
          </Grid>
      </Grid>
      {/*<ProductOptionDialog
        open={productOptionDialogOpen}
        onReceive={handleProductOptionDialogData}
        onClose={handleProductOptionDialogClose}/>*/}
      <ProductLinkDialog
        open={productLinkDialogOpen}
        onReceive={handleProductLinkDialogData}
        onClose={handleProductLinkDialogClose}/>
    </Box>
  );
}

export default MenuAdminUpdatePage;
