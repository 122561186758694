import React, {useEffect, useState, useRef} from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import TagIcon from '@mui/icons-material/Tag';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import ScrollTop from '../../components/common/ScrollTop';

import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../../assets/Constants';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip,
  InputAdornment
} from '@mui/material';

import MenuItem from '../../components/menu/MenuItem';
import MenuListItem from '../../components/menu/MenuListItem';

import Footer from '../../components/common/Footer';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ProductController from '../../apis/products/ProductController';

import CategoryList from '../../resources/category/CategoryList';
import MenuViewTypeSelector from '../../components/inputs/MenuViewTypeSelector';

import { blue, amber, orange, deepOrange } from '@mui/material/colors';

const drawerWidth = 240;

const StyledTextField = styled(TextField)`
    fieldset {
        border-radius: 28px;
        border-color: #ffffff;
        color: #ffffff;
      },
    label {
        color:#ffffff;
    },
    input {
      color: #ffffff;
    },
    .Mui-focused fieldset {
        border-color: #ffffff;
    }
`;

const CATEGOTY_LIST = CategoryList;

function MenuSearchPage(props) {

  const { window,
    __clientProductOption,
    __clientProductViewTypeChange,
    __clientProductCategoryChange} = props;

  const navigate = useNavigate();

  const searchRef = useRef(null);
  const container = useRef(null);

  const [searchParams] = useSearchParams();
  const query = searchParams.get('keyword'); // test

  const [isProductLoading, setIsProductLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [load, setLoad] = useState(false);

  const [viewType, setViewType] = useState(__clientProductOption.viewType);

  const getProductSuccess = (response) => {

    if(response.status === 200){
      setProducts(response.data.products);
    }
    setIsProductLoading(false);
  };

  const getProductError = (error) => {
    setIsProductLoading(false);
  };

  const activeEnter = (e) => {
    if(e.key === "Enter") {
      handleClickSearch();
    }
  }

  const handleMainMenu = () => {
    navigate(`/menu`);
  };

  const onViewTypeChange = (type) => {
    setViewType(type);
    __clientProductViewTypeChange({viewType: type});
  }

  const handleClickSearch = () => {
    const keyword = searchRef.current.value;
    if(keyword.length < 1){
      return;
    }

    navigate(`/menu/search?keyword=${keyword}`);
    navigate(0);
  };

  useEffect(() => {
    if(isProductLoading){
      ProductController.getProducts({query:query}, getProductSuccess, getProductError);
    }
  }, [isProductLoading]);

  useEffect(() => {
    setLoad(true);
  },[]);

  return (
    <Box
      id="MAIN-CONTAINER"
      ref={container}
      sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative', boxSizing:'border-box', overflow:'scroll' }}>
      <Box sx={{width:'100%', position:'relative'}}>
        <img src="/images/menu_image.jpg"
          style={{position:'absolute', width:'100%', height:'100%', top:'0px', left:'0px', right:'0px', bottom:'0px', objectFit:'cover'}}/>
        <Box sx={{
          position:'relative',
          width:'100%',
          height:'100%',
          padding:'72px 0px',
          top:'0px', left:'0px', right:'0px', bottom:'0px',
          background:'linear-gradient(180deg, rgba(0,0,0,.3), #ffffff)'}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{width:'100%', height:'100%', position:'relative', padding:'0px 24px', boxSizing:'border-box'}}
            spacing={2}>
            <Typography variant="h6" element="p" sx={{
              fontFamily: 'Gowun Bold',
              textAlign:"center", fontSize:{xs:'22px', md:'32px'}, fontWeight:900, color:'#ffffff'}}>
              좋아하는 위스키를 검색해보세요
            </Typography>
            <Button
              variant="outlined"
              onClick={handleMainMenu}
              sx={{
                fontFamily: 'Gowun Bold',
                color:'#ffffff', borderColor:'#ffffff'}}
              startIcon={<ArrowBackIcon sx={{color:'#ffffff'}} />}>
              메인 메뉴로 돌아가기
            </Button>
            <Box sx={{ width:'100%', maxWidth:'720px'}}>
              <StyledTextField
                onKeyDown={activeEnter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{color:'#ffffff'}}/>
                    </InputAdornment>
                  ),
                  endAdornment:<InputAdornment position="end">
                   <IconButton
                     aria-label={
                       'search keyword send'
                     }
                     onClick={handleClickSearch}>
                     <SendTwoToneIcon sx={{color:'#ffffff'}}/>
                   </IconButton>
                 </InputAdornment>
                }}
                sx={{
                  width:'100%',
                  borderColor:'#ffffff',
                  borderRadius:'24px',
                  color:"#ffffff"
                }}
                inputRef={searchRef}
                defaultValue={query}
                fullWidth
                label="검색어"
                id="fullWidth" />
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%', textAlign:'right', mb:'24px', pr:'16px'}}>
          <MenuViewTypeSelector
            value={viewType}
            onChange={onViewTypeChange}/>
        </Box>
        <Box sx={{position:'relative', width:'100%'}}>
          <Typography variant="h6" element="p" sx={{
            fontFamily: 'Gowun Bold',
            textAlign:"center", fontSize:'32px', fontWeight:900, color:'#222222'}}>
            검색 결과
          </Typography>
        </Box>
      </Box>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container
            spacing={2}
            sx={{width:'100%'}}>
            {products && products.map((el) => (
              (viewType === PRODUCT_CARD_VIEW) ?
              <MenuItem
                key={el.key}
                item={el}/> :
              <MenuListItem
                key={el.key}
                item={el}/>
            ))}
          </Grid>
        </Box>
      </Box>
      <Footer/>
      {load && <ScrollTop {...props}
        parent={container}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>}
    </Box>
  );
}

MenuSearchPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default MenuSearchPage;
