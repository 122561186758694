import moment from 'moment';
import 'moment/locale/ko';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../assets/Constants';

export const CLIENT_PRODUCT_VIEW_TYPE = "CLIENT_PRODUCT_VIEW_TYPE";
export const CLIENT_PRODUCT_CATEGORY = "CLIENT_PRODUCT_CATEGORY";

export const clientProductViewTypeChange = (option) => ({ type: CLIENT_PRODUCT_VIEW_TYPE, ...option });
export const clientProductCategoryChange = (option) => ({ type: CLIENT_PRODUCT_CATEGORY, ...option });


const initalState = {
  viewType : PRODUCT_LIST_VIEW,
  selectCategory : ""
};

export default function clientProductControl (state = initalState, action) {
  switch (action.type) {
    case CLIENT_PRODUCT_VIEW_TYPE:
      return {
        ...state,
        viewType: action.viewType
      };
    case CLIENT_PRODUCT_CATEGORY:
      return {
        ...state,
        selectCategory: action.category
      };

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};
