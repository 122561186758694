import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


import {
  LoginPage,
  FindPasswordPage,
  HomePage,
  AboutPage,
  MenuPage,
  MenuSearchPage,
  ItemPage,
  EventPage,
  RedirectPage,

  NoticeContentPage,
  NoticePage,

  QueueLayout,
  NonSignedLayout,
  NonSignedSubLayout,
  NonNothingLayout,

  ReservationPage
} from '../containers'

export default function NonSignedRoute(props){
  return (
    <Routes>
      <Route path='/' element={
        <QueueLayout>
          <NonSignedLayout type={0}>
            <HomePage token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/about' element={
        <QueueLayout>
          <NonSignedLayout type={0}>
            <AboutPage token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/menu' element={
        <QueueLayout>
          <NonSignedLayout>
            <MenuPage {...props} token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/menu/search' element={
        <QueueLayout>
          <NonSignedLayout>
            <MenuSearchPage {...props} token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/item/:id' element={
        <QueueLayout>
          <NonSignedSubLayout>
            <ItemPage token={props.token} setToken={props.setToken} />
          </NonSignedSubLayout>
        </QueueLayout>
      } />
      <Route path='/events' element={
        <QueueLayout>
          <NonSignedLayout>
            <EventPage token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/event/:id' element={
        <QueueLayout>
          <NonSignedSubLayout>
            <ItemPage token={props.token} setToken={props.setToken} />
          </NonSignedSubLayout>
        </QueueLayout>
      } />
      <Route path='/notices' element={
        <QueueLayout>
          <NonSignedLayout>
            <NoticePage token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/notice/:id' element={
        <QueueLayout>
          <NonSignedSubLayout>
            <NoticeContentPage token={props.token} setToken={props.setToken} />
          </NonSignedSubLayout>
        </QueueLayout>
      } />
      <Route path='/signin' element={
        <QueueLayout>
          <NonNothingLayout>
            <LoginPage token={props.token} setToken={props.setToken} />
          </NonNothingLayout>
        </QueueLayout>
      } />
      <Route path='/reservation' element={
        <QueueLayout>
          <NonSignedLayout>
            <ReservationPage token={props.token} setToken={props.setToken} />
          </NonSignedLayout>
        </QueueLayout>
      } />
      <Route path='/forward/youtube' element={
        <RedirectPage
          loc="https://www.youtube.com/@Eaudevie_whiskey"/>
      } />
      <Route path='/forward/instagram' element={
        <RedirectPage
          loc="https://www.instagram.com/eaudevie8"/>
      } />
      <Route path='*' element={<Navigate to="/" />} />
    </Routes>
  );
}
