import moment from 'moment';
import 'moment/locale/ko';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../assets/Constants';

export const ADMIN_PRODUCT_OPTION = "ADMIN_PRODUCT_OPTION";
export const ADMIN_PRODUCT_CATEGORY = "ADMIN_PRODUCT_CATEGORY";
export const ADMIN_PRODUCT_KEYWORD = "ADMIN_PRODUCT_KEYWORD";

export const adminProductOptionChange = (statistics) => ({ type: ADMIN_PRODUCT_OPTION, ...statistics });
export const adminProductCategoryChange = (value) => ({ type: ADMIN_PRODUCT_CATEGORY, ...value });
export const adminProductKeywordChange = (value) => ({ type: ADMIN_PRODUCT_KEYWORD, ...value });


const initalState = {
  viewType : PRODUCT_CARD_VIEW,
  category : [],
  keyword : ""
};

export default function adminProductControl (state = initalState, action) {
  switch (action.type) {
    case ADMIN_PRODUCT_OPTION:
      return {
        ...state,
        viewType: action.viewType
      };
    case ADMIN_PRODUCT_CATEGORY:
      return {
        ...state,
        category: action.category
      };
    case ADMIN_PRODUCT_KEYWORD:
      return {
        ...state,
        keyword: action.keyword
      };

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};
