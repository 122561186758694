import React, {useEffect, useState, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import WineBarTwoToneIcon from '@mui/icons-material/WineBarTwoTone';
import LiquorIcon from '@mui/icons-material/Liquor';
import TagIcon from '@mui/icons-material/Tag';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import LocalFloristTwoToneIcon from '@mui/icons-material/LocalFloristTwoTone';
import EmojiFoodBeverageTwoToneIcon from '@mui/icons-material/EmojiFoodBeverageTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ThumbUpOffAltTwoToneIcon from '@mui/icons-material/ThumbUpOffAltTwoTone';

import {
  Grid,
  Button,
  Container,
  Stack,
  Chip
} from '@mui/material';

import Footer from '../../components/common/Footer';
import ItemCarousel from '../../components/item/ItemCarousel';
import RecommendCarousel from '../../components/item/RecommendCarousel';

import ProductController from '../../apis/products/ProductController';

import { blue, amber, orange, deepOrange } from '@mui/material/colors';

const drawerWidth = 240;

function ItemPage(props) {

  const params = useParams();
  const [isProductLoading, setIsProductLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [recommends, setRecommends] = useState([]);

  const { id } = params;

  const getProductSuccess = (response) => {

    if(response.status === 200){

      setProduct(response.data.product);
      setProductImages(response.data.product.images);
      setRecommends(response.data.product.recommend);
    }
    setIsProductLoading(false);
  };

  const getProductError = (error) => {
    setIsProductLoading(false);
  };

  const updateProductSuccess = (response) => {

  };

  const updateProductError = (error) => {

  };

  useEffect(() => {
    if(isProductLoading){
      ProductController.getProduct(
        { id : id },
        getProductSuccess, getProductError);
    }
  }, [isProductLoading]);

  useEffect(() => {
    const timeId = setTimeout(() => {

      ProductController.updateProductView(
        { id : id },
        updateProductSuccess, updateProductError);
    }, 5000);

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative', boxSizing:'border-box', overflow:{xs:'scroll', sm : 'scroll', md : 'hidden'} }}>
      <Box sx={{width:{md : '100%', sm : '100%'}, backgroundColor:"#ffffff", boxSizing:'border-box'}}>
        <Box sx={{position:'relative', width:'100%'}}>
          <Grid
            container sx={{width:'100%', position:'relative'}}>
            <Grid item xs={12} sm={12} md={6} sx={{ position:'relative', height:{md:'calc(100vh - 64px)', sm:'100vw', xs:'100vw'}}}>
              <ItemCarousel
                images={productImages}/>
            </Grid>
            <Grid item sm={12} md={6} sx={{p:{md : "36px 72px", xs:"36px 24px"}, overflow:'scroll', height:{md:'calc(100vh - 64px)', sm:'auto', xs:'auto'}}}>
              <Box sx={{width:'100%', position:'relative', overflow:'scroll'}}>
                <Box sx={{width:'100%', height:{md:'96px', xs:'24px'}}}></Box>
                <Stack
                  sx={{position:'relative', width:'100%', boxSizing:'border-box'}}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}>

                  <Chip icon={<ThumbUpOffAltTwoToneIcon fontSize="small" />} label="Best"  color="warning" sx={{p:1}} />

                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:900, mb:'24px', fontSize:'36px'}}>
                    {product.title && product.title.kr}
                  </Typography>
                  {/*<Box sx={{
                    position:'relative',
                    boxSizing:'border-box',
                    width:'100%',
                    backgroundColor:'#dddddd',
                    padding:'16px 24px',
                    mb:'24px',
                    borderRadius:'16px'}}>
                    <Typography element="p" sx={{textAlign:"left", fontWeight:900, fontSize:'32px', color:'#333333'}}>
                      〃
                    </Typography>
                    <Typography element="p" sx={{
                      fontFamily: 'Gowun Bold',
                      textAlign:"center", fontWeight:900, fontSize:'18px', color:'#333333'}}>
                      {product.description && product.description.short}
                    </Typography>
                    <Typography element="p" sx={{textAlign:"right", fontWeight:900, fontSize:'32px', color:'#333333'}}>
                      〃
                    </Typography>
                  </Box>*/}
                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:500, color:'#555', mb:'12px', fontSize:'20px'}}>
                  {product.description && product.description.description}
                  </Typography>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{marginBottom:'24px'}}
                    spacing={2}>
                    <WineBarTwoToneIcon
                      fontSize="small"/>
                    <Typography element="p" sx={{
                      fontFamily: 'Gowun Bold',
                      textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                      {product.price && product.price.shot.toLocaleString()}
                    </Typography>
                  </Stack>
                  {product.price && product.price.bottle > 0 && <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{marginBottom:'48px'}}
                    spacing={2}>
                    <LiquorIcon
                      fontSize="small"/>
                    <Typography element="p" sx={{
                      fontFamily: 'Gowun Bold',
                      textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                      {product.price && product.price.bottle.toLocaleString()}
                    </Typography>
                  </Stack>}

                  <Box sx={{width:'100%', height:'36px'}}></Box>

                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:900, mt:'24px', mb:'12px', fontSize:'24px', color:'#444444'}}>
                    풍미와 향
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{marginBottom:'24px'}}
                    spacing={2}>
                    <LocalFloristTwoToneIcon
                      fontSize="small"/>
                    <Typography element="p" sx={{
                      fontFamily: 'Gowun Bold',
                      textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                      노즈(향)
                    </Typography>
                  </Stack>
                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:500, mb:'12px', fontSize:'16px'}}>
                    {product.description && product.description.specification[0].d}
                  </Typography>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{marginBottom:'24px'}}
                    spacing={2}>
                    <EmojiFoodBeverageTwoToneIcon
                      fontSize="small"/>
                    <Typography element="p" sx={{
                      fontFamily: 'Gowun Bold',
                      textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                      팔레트(맛)
                    </Typography>
                  </Stack>
                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:500, mb:'12px', fontSize:'16px'}}>
                    {product.description && product.description.specification[1].d}
                  </Typography>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{marginBottom:'24px'}}
                    spacing={2}>
                    <CheckCircleTwoToneIcon
                      fontSize="small"/>
                    <Typography element="p" sx={{
                      fontFamily: 'Gowun Bold',
                      textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                      피니시(후미)
                    </Typography>
                  </Stack>
                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:500, mb:'12px', fontSize:'16px'}}>
                    {product.description && product.description.specification[2].d}
                  </Typography>

                  <Box sx={{width:'100%', height:'36px'}}></Box>

                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:900, mt:'24px', mb:'12px', fontSize:'24px', color:'#444444'}}>
                    Eauduevie's comment
                  </Typography>
                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontWeight:500, mb:'12px', fontSize:'16px'}}>
                    {product.description && product.description.comment}
                  </Typography>
                  <Box sx={{position:'relative', width:'100%', height:'48px'}}></Box>
                  <Typography element="p" sx={{
                    fontFamily: 'Gowun Bold',
                    textAlign:"left", fontSize:'18px', fontWeight:'900'}}>
                    연관된 위스키
                  </Typography>
                  <Box sx={{width:'100%', position:'relative'}}>
                    <RecommendCarousel
                      items={recommends}/>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>);
}

ItemPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ItemPage;
