import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

//icon list
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import PlaylistAddTwoToneIcon from '@mui/icons-material/PlaylistAddTwoTone';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import CheckIcon from '@mui/icons-material/Check';

import LiquorTwoToneIcon from '@mui/icons-material/LiquorTwoTone';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';

import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

// heartit controller
import LikeController from '../../apis/products/LikeController';

import { pink, blue, red } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardView(props) {

  const { info, onDelete } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleShowDetail = (event) => {
    navigate(`/admin/product/${info.key}`);
  }

  const handleDelete = () => {
    onDelete();
  };

  const getTimestamp = (id) => {
    let timestamp = id.toString().substring(0,8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return moment(date).fromNow();
  }

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }
  const storageChip = (status) => {
    switch(status){
      case 0 : return (
        <Chip
          color='error'
          clickable
          onClick={props.onStorage}
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<RemoveShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'품절'} />
      );
      case 1 : return (
        <Chip
          color='success'
          clickable
          onClick={props.onStorage}
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'재고있음'} />
      );
      case 2 : return (
        <Chip
          color='warning'
          clickable
          onClick={props.onStorage}
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'부분재고'} />
      );
      default : return (<></>);
    }
  }

  /*useEffect(() => {
    props.onSelect(props.isSelected);
  },[props.isSelected]);*/

  return (
    <Card
      elevation={0}
      sx={{ position:'relative',height:'100%', width:"100%", backgroundColor:(info.active ? "#ffffff" : "#eeeeee"), border:(props.isSelected ? `solid 1px ${blue[500]}`:"solid 1px #eee") }}>
      <CardHeader
        title={
          <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
          <Tooltip title={info.description.type}>
            <Chip label={info.description.type} size="small" sx={{marginBottom:'4px', fontSize:'12px'}} />
          </Tooltip>
          {!info.active && <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '12px',
            mb:'2px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            }}>
            <a style={{textDecoration:'none', color:'#ff4444'}}>노출 안함</a>
          </Typography>}
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '18px',
            mb:'8px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            }}>
            <a style={{textDecoration:'none', color:'#222222'}}>{info.title.kr}</a>
          </Typography>
          </Box>
        }
        subheader={
          <>
            <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:900}}>
              <LiquorTwoToneIcon sx={{fontSize:'12px', mr:'6px'}} />
              {(new Intl.NumberFormat('ko', { style: 'currency', currency: 'krw' }).format(info.price.shot))}
            </Typography>
            <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:900}}>
              <LocalBarTwoToneIcon sx={{fontSize:'12px', mr:'6px'}}/>
              {(new Intl.NumberFormat('ko', { style: 'currency', currency: 'krw' }).format(info.price.bottle))}
            </Typography>
          </>
        }
      />
      <Box sx={{position:'relative', width:'100%', height:'194px'}}>
        <CardMedia
          component="img"
          height="194"
          image={info.images.length > 0 ? `/api/product/image/${info.images[0]}` : "" }
          sx={{objectFit:"contain"}}
          alt={`product-images-${info.title.kr}`}
        />
        <Box sx={{position:'absolute', bottom:'12px', right:'12px'}}>
          <Chip icon={<ImageTwoToneIcon size="small" />} size="small" label={info.images.length} />
        </Box>
      </Box>
      <CardContent>
        <Typography variant="body2" color="text.secondary" component="p" sx={{
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          }}>
          {info.description.short}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="share" onClick={handleDelete}>
          {<DeleteTwoToneIcon />}
        </IconButton>
        <Button onClick={handleShowDetail} variant="text" sx={{marginLeft:'auto'}}>
         자세히 보기
         <ArrowRightTwoToneIcon />
        </Button>
      </CardActions>
    </Card>
  );
}
