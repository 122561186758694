import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import ViewDayTwoToneIcon from '@mui/icons-material/ViewDayTwoTone';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../../assets/Constants';

export default function ViewTypeSelector(props) {

  const { value } = props;

  const handleChange = (event, newViewType) => {
    props.onChange(newViewType);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      size="small"
      exclusive
      onChange={handleChange}
      aria-label="ViewType"
    >
      <ToggleButton value={PRODUCT_CARD_VIEW}>
        <GridViewTwoToneIcon sx={{marginRight:"12px"}}/>
        카드뷰
      </ToggleButton>
      <ToggleButton value={PRODUCT_LIST_VIEW}>
        <ViewDayTwoToneIcon sx={{marginRight:"12px"}}/>
        리스트뷰
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
